import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const PaymentProductsPage = (props) => {
    const components = props.data.cms.paymentProductsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components}></DynamicZone>
        </Layout>
    );
};

export default PaymentProductsPage;

export const query = graphql`
query ($locale: String!){
  cms {
    paymentProductsPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          headerSubtitle: subtitle
          backgroundImageHeight
          backgroundImage {
            url
          }
          contentOffset
          mobileContentOffset
          primaryImage {
            url
          }
        }
        ... on STRAPI_ComponentComponentsTheyTrustUs {
          __typename
          sectionTitle {
            tailwindClasses
            title
            style
            description
            ytLink
          }
          images {
            url
          }
          mobileImages {
              url
          }
          linesVisible
          theyTrustUsScrollColor
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          sectionTitle {
            tailwindClasses
            ytLink
            title
            style
            description
          }
          topPusherHeight
          tabHeight
          topPusherHeightMobile
          tabs {
            label
            labelColour
            labelImage {
              url
            }
            images {
              url
            }
            type
            title
            subtitle
            link
          }
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          sectionTitle {
            tailwindClasses
            ytLink
            style
            title
            description
          }
          topPusherHeight
          topPusherHeightMobile
          hasDarkBackground
          mobileItemsToLeft
          benefitsScrollColor
          Benefit {
            title
            subtitle
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsMoneyLicenses {
          __typename
          moneyLicence {
            title
            titleLink
            content
            image {
              url
            }
          }
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          image {
            url
          }
          topPusherHeight
          topPusherHeightMobile
          singleImageContent
        }
      }
    }
  }
}

`;
